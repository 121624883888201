import React, { useEffect, useState } from 'react';
import { db } from '../../services/firebase';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../context/auth';
import { 
  Table, Tbody, Td, Th, Thead, Tr, Button, 
  Modal, ModalOverlay, ModalContent, ModalHeader, 
  ModalFooter, ModalBody, ModalCloseButton, useDisclosure,
  Box, Divider, Flex, Input, FormControl, FormLabel 
} from '@chakra-ui/react';

const OrdersTable = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedOrder, setUpdatedOrder] = useState({});
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  useEffect(() => {
    const fetchOrders = async () => {
      if (!user?.businessName) return;

      const orderCollectionRef = collection(db, `orders/${user.businessName}/orders`);
      const orderSnapshot = await getDocs(orderCollectionRef);
      const orderList = orderSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setOrders(orderList);
    };

    fetchOrders();
  }, [user?.businessName]);

  const handleRowClick = (order) => {
    setSelectedOrder(order);
    setUpdatedOrder(order);
    onOpen();
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedOrder((prevOrder) => ({ ...prevOrder, [name]: value }));
  };

  const handleSubmit = async () => {
    const orderDocRef = doc(db, `orders/${user.businessName}/orders`, selectedOrder.id);
    await updateDoc(orderDocRef, updatedOrder);
    setOrders((prevOrders) => 
      prevOrders.map(order => (order.id === selectedOrder.id ? updatedOrder : order))
    );
    setIsEditing(false);
    onClose();
  };

  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <Th>Order ID</Th>
            <Th>Status</Th>
            <Th>Total</Th>
            <Th>Customer</Th>
            <Th>Order Date</Th>
            <Th>Delivery Date</Th>
          </Tr>
        </Thead>
        <Tbody>
          {orders.map((order, index) => (
            <Tr key={index} onClick={() => handleRowClick(order)} cursor="pointer">
              <Td>{order.orderid}</Td>
              <Td>{order.status}</Td>
              <Td>{order.totalAmount}</Td>
              <Td>{order.customer}</Td>
              <Td>{order.orderDate}</Td>
              <Td>{order.deliveryDate}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Order Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedOrder && (
              <Box>
                {isEditing ? (
                  <Flex direction="column" gap={4}>
                    {Object.keys(updatedOrder).map((key) => (
                      <FormControl key={key}>
                        <FormLabel>{key}</FormLabel>
                        <Input 
                          name={key} 
                          value={updatedOrder[key]} 
                          onChange={handleChange} 
                          readOnly={key === 'id'} 
                        />
                      </FormControl>
                    ))}
                  </Flex>
                ) : (
                  <Flex direction="column" gap={4}>
                    <Box>
                      <strong>Order Info</strong>
                      <Divider />
                      <p><strong>Order ID:</strong> {selectedOrder.orderid}</p>
                      <p><strong>Status:</strong> {selectedOrder.status}</p>
                      <p><strong>Order Date:</strong> {selectedOrder.orderDate}</p>
                      <p><strong>Delivery Date:</strong> {selectedOrder.deliveryDate}</p>
                      <p><strong>Completed Date:</strong> {selectedOrder.completedDate}</p>
                      <p><strong>Payment Method:</strong> {selectedOrder.paymentMethod}</p>
                    </Box>
                    
                    <Box>
                      <strong>Customer Info</strong>
                      <Divider />
                      <p><strong>Customer:</strong> {selectedOrder.customer}</p>
                      <p><strong>Phone:</strong> {selectedOrder.phoneNumber}</p>
                      <p><strong>Email:</strong> {selectedOrder.email}</p>
                      <p><strong>Address:</strong> {selectedOrder.address}</p>
                      <p><strong>First Name:</strong> {selectedOrder.firstName}</p>
                      <p><strong>Last Name:</strong> {selectedOrder.lastName}</p>
                      <p><strong>Full Name:</strong> {selectedOrder.fullName}</p>
                    </Box>

                    <Box>
                      <strong>Order Details</strong>
                      <Divider />
                      <p><strong>Summary:</strong> {selectedOrder.summaryInfo}</p>
                      <p><strong>Total:</strong> {selectedOrder.totalAmount}</p>
                      <p><strong>Service Used:</strong> {selectedOrder.serviceUsed}</p>
                      <p><strong>Receipt:</strong> {selectedOrder.receipt}</p>
                      <p><strong>Items Info:</strong> {selectedOrder.itemsInfo}</p>
                      <p><strong>Notes:</strong> {selectedOrder.notes}</p>
                      <p><strong>Special Instructions:</strong> {selectedOrder.specialInstructions}</p>
                      <p><strong>Promo Code:</strong> {selectedOrder.promoCode}</p>
                      <p><strong>Discount Used:</strong> {selectedOrder.discountUsed}</p>
                    </Box>
                  </Flex>
                )}
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            {isEditing ? (
              <>
                <Button colorScheme="blue" mr={3} onClick={handleSubmit}>Save</Button>
                <Button onClick={() => setIsEditing(false)}>Cancel</Button>
              </>
            ) : (
              <Button colorScheme="blue" mr={3} onClick={handleEditClick}>Edit</Button>
            )}
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default OrdersTable;
