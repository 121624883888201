import React from "react";
import { Link, useLocation } from "react-router-dom";
import { HiMenuAlt4 } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { FaUser } from "react-icons/fa"; // Importing FaUser icon
import logo from '../../assets/leafnet.png';

const NavBarItem = ({ title, classprops, to, icon }) => {
  const location = useLocation(); // Hook to get current location
  const isActive = location.pathname === to;

  // Conditional styling for active link
  const activeStyle = isActive ? 'text-[#2896b1]' : 'text-[#2a3650]';

  return (
    <Link to={to}>
      <li className={`mx-4 cursor-pointer font-bold ${classprops} ${activeStyle}`}>
        {icon ? icon : title}
      </li>
    </Link>
  );
};

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);

  return (
    <nav className="w-full flex md:justify-center justify-between  bg-white fixed top-0  z-50  max-h-24 ">
      <div className="md:flex-[0.5] flex-initial justify-center items-center ">
        <Link to="/">
          <img  src={logo} alt="logo" className="w-64 max-h-24 cursor-pointer hover:scale-105 hover:rotate-3 transition ease-in-out object-contain" />
        </Link>
      </div>
      <ul className="text-[#8e40e1] md:flex hidden list-none flex-row justify-between items-center flex-initial">
        <NavBarItem className="hover:scale-105 hover:-rotate-3" title="Dashboard" to="/" />
        <NavBarItem  className="hover:scale-105 hover:-rotate-3" title="Marketing" to="/marketing" />
        <NavBarItem  className="hover:scale-105 hover:-rotate-3" title="Inventory" to="/inventory" />
        <NavBarItem  className="hover:scale-105 hover:-rotate-3" title="Customers" to="/customers" />
        <NavBarItem  className="hover:scale-105 hover:-rotate-3" title="Orders" to="/orders" />
        <NavBarItem className="hover:scale-105 hover:-rotate-3" title="Account" to="/profile" icon={<FaUser size={20} />} />

      </ul>
      <div className="flex relative">
        {!toggleMenu && (
          <HiMenuAlt4
            fontSize={28}
            className="text-[#8e40e1] md:hidden cursor-pointer"
            onClick={() => setToggleMenu(true)}
          />
        )}

        {toggleMenu && (
          <ul
            className="z-10 fixed -top-0 -right-2 p-3 w-[70vw] h-screen shadow-2xl md:hidden list-none
            flex flex-col justify-start items-end rounded-md blue-glassmorphism text-[#8e40e1] animate-slide-in"
          >
            <li className="text-xl w-full my-2">
              <AiOutlineClose
              className="cursor-pointer"
                fontSize={32}
                onClick={() => setToggleMenu(false)}
              />
            </li>
            <NavBarItem
            
              title="Dashboard"
              to="/"
              classprops="my-2 text-lg hover:scale-105 hover:rotate-3"
            />
           <NavBarItem
            
            title="Marketing"
            to="/marketing"
            classprops="my-2 text-lg hover:scale-105 hover:rotate-3"
          />
            <NavBarItem
              title="profile"
              to="/profile"
              classprops="my-2 text-lg hover:scale-105 hover:rotate-3"
            />   
            <NavBarItem
              title="customers"
              to="/customers"
              classprops="my-2 text-lg hover:scale-105 hover:rotate-3"
            />   
            <NavBarItem
              title="orders"
              to="/orders"
              classprops="my-2 text-lg hover:scale-105 hover:rotate-3"
            />        
            <NavBarItem
            title="inventory"
            to="/inventory"
            classprops="my-2 text-lg hover:scale-105 hover:rotate-3"
          />
          </ul>
        )}
      </div>
    </nav>
  );
};

export default Navbar;