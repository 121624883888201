import React, { useEffect, useState,  } from 'react';
import { useAuth } from '../../context/auth';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar/NavBar';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../../services/firebase'; // Make sure this path is correct to your Firebase config

import FileUpload from './FileUpload';
import InventoryTable from './InventoryTable';

const Inventory = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = 'Inventory | LeafNet';
  
    const fetchInventoryData = async () => {
      setLoading(true);
      try {
        const businessName = user?.businessName; // Adjust based on your user object
        if (!businessName) {
          console.error('No business name found for user');
          setLoading(false);
          return;
        }
  
        const inventoryCollectionRef = collection(db, `inventory/${businessName}/items`);
        const q = query(inventoryCollectionRef);
        const querySnapshot = await getDocs(q);
  
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push({ id: doc.id, ...doc.data() });
        });
  
        setInventory(items);
      } catch (error) {
        console.error('Error fetching inventory data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchInventoryData();
  }, [user]); // Depend on user to re-fetch when the user object changes
  
  const updateInventoryItem = (updatedItem, deletedItemId = null) => {
    if (deletedItemId) {
      // Handle deletion
      setInventory(prevInventory => prevInventory.filter(item => item.id !== deletedItemId));
    } else if (updatedItem) {
      // Handle update
      setInventory(prevInventory => prevInventory.map(item => 
        item.id === updatedItem.id ? updatedItem : item));
    }
  };
  
  const isBusinessOwner = user?.businessLicense; // Adjust based on your actual user object structure
  const isEmployee = user?.businessId && !isBusinessOwner;

  return (
    <>
    <Navbar />
      <div className="pt-30 md:pt-72 ">
      <div className="container mx-auto">
        <p className='text-gray-700 font-bold text-3xl mb-4'>
          Inventory
        </p>
        <p className='bg-gray-900 text-white'>
          {isBusinessOwner ? 'Business Owner View' : isEmployee ? 'Employee View' : 'Welcome'}
        </p>

        {/* Conditional UI for Business Owners */}
        {isBusinessOwner && (
        <>
              <FileUpload />
              <InventoryTable inventory={inventory} updateInventoryItem={updateInventoryItem} />
          </>
        )}

        {/* Conditional UI for Employees */}
        {isEmployee && (
          <div>



          </div>
        )}

        <div className='flex' >
  
          </div>
        </div>
      </div>
    </>
  );
};

export default Inventory;
