import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../../context/auth';
import { collection, getDocs, query, orderBy, addDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../../services/firebase';
import FaPlus from '../../assets/FaPlus.png';
import FaSearch from '../../assets/FaSearch.png';
import ArrowRight from '../../assets/ArrowRight.png';

function Messenger() {
  const { user } = useAuth();
  const [customers, setCustomers] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const bottomOfChat = useRef(null);
  const [inputText, setInputText] = useState('');

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleSendClick = async () => {
    if (selectedChat && inputText) {
      try {
        const fromNumber = await getFromNumber(user.businessName);
        const toNumber = selectedChat.messages[0].from;
  
        const response = await fetch('https://one776server.onrender.com/send-sms', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            from: fromNumber,
            to: toNumber,
            text: inputText,
          }),
        });
        
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`Failed to send SMS: ${errorData.error}`);
        }
  
        const responseData = await response.json();
        console.log(responseData);
  
        const newMessage = {
          from: fromNumber,
          to: toNumber,
          text: inputText,
          timestamp: new Date(),
        };
        await addDoc(collection(db, `/smsMessages/${user.businessName}/messages/${selectedChat.name}/messages`), newMessage);
        setMessages([...messages, newMessage]);
        setInputText('');
      } catch (error) {
        console.error('Error sending SMS:', error);
      }
    }
  };
  

  const getFromNumber = async (businessName) => {
    const docRef = doc(db, `/smsNumber/${businessName}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data().marketNumber;
    } else {
      throw new Error('No such document!');
    }
  };

  const fetchCustomersAndMessages = async () => {
    const customerNames = await getCustomerNames(user.businessName);

    const customersWithMessages = await Promise.all(customerNames.map(async (name) => {
      const customerMessages = await fetchMessagesForCustomer(name);
      return { name, messages: customerMessages };
    }));

    setCustomers(customersWithMessages.filter(customer => customer.messages.length > 0));
  };

  const getCustomerNames = async (businessName) => {
    const customersRef = collection(db, `/businessCustomers/${businessName}/customers`);
    const snapshot = await getDocs(customersRef);
    return snapshot.docs.map(doc => doc.data().fullName);
  };

  const fetchMessagesForCustomer = async (customerName) => {
    const messagesRef = collection(db, `/smsMessages/${user.businessName}/messages/${customerName}/messages`);
    const q = query(messagesRef, orderBy("timestamp", "asc"));
    const messagesSnapshot = await getDocs(q);
    return messagesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  };

  useEffect(() => {
    fetchCustomersAndMessages();
  }, [user.businessName]);

  useEffect(() => {
    bottomOfChat.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleCustomerClick = (customer) => {
    setSelectedChat(customer);
    setMessages(customer.messages);
  };

  return (
    <div className='ml-[20rem]'>
      {/* Customer List */}
      <div className="w-[200px] h-[450px] bg-white rounded-[15px] border-4 border-teal-300 ">
        <div className='w-12 h-12 bg-teal-300 rounded-[15px] mt-2 relative ml-8'>
          <img src={FaPlus} className='w-8 h-8 absolute mt-2 ml-2 ' />
        </div>
        <div className='w-12 h-12 bg-teal-300 rounded-[15px] -mt-12 relative ml-[7rem]'>
          <img src={FaSearch} className='w-8 h-8 absolute mt-2 ml-2 ' />
        </div>
        <div>
          {customers.map(customer => (
            <div className='cursor-pointer w-[175px] h-[50px] bg-slate-400 rounded-[5px] mt-2 relative ml-2' key={customer.name} onClick={() => handleCustomerClick(customer)}>
              <h1 className='text-white text-[20px] text-center font-bold'> {customer.name} </h1>
            </div>
          ))}
        </div>

        {selectedChat && (
          <div className="w-[350px] h-[450px] bg-white rounded-[15px] border-4 border-teal-300 ml-[15rem] relative -mt-[11rem]">
            <h2>Chat with {selectedChat.name}</h2>
            <div className="chat-messages" style={{ maxHeight: '300px', overflowY: 'auto' }}>
              {messages.map((message, index) => {
                const isOutbound = message.from === selectedChat.messages[0].from;
                const messageStyle = isOutbound ? 'bg-zinc-100' : 'bg-blue-100';
                return (
                  <div className={`${messageStyle} rounded-[15px] shadow p-2 my-2`} key={index}>
                    <p>{message.text}</p>
                  </div>
                );
              })}
              <div ref={bottomOfChat} />
            </div>
            <input type="text" className='w-[260px] bg-zinc-100 rounded-[3px] shadow mt-20 ml-7' value={inputText} onChange={handleInputChange} />
            <div className='w-8 h-8 -mt-9 relative ml-[18rem] cursor-pointer' onClick={handleSendClick}>
              <img src={ArrowRight} className='w-6 h-6 absolute mt-3 ml-2' />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Messenger;
