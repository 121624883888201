import React, { useState, useRef } from 'react';
import {
  Button, Table, Thead, Tbody, Tr, Th, Td, IconButton, useDisclosure,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
  ModalBody, ModalFooter, FormControl, FormLabel, Input, AlertDialog,
  AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useAuth } from '../../context/auth';


const CustomersTable = ({ customers, updateCustomers }) => {
  const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useDisclosure();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const { user } = useAuth();
  const onCloseAlert = () => setIsAlertOpen(false);
  const cancelRef = useRef();
  const businessName = user?.businessName;

  const handleEditClick = (customer) => {
    setSelectedCustomer(customer);
    onEditModalOpen();
  };

  const handleSaveEdit = async () => {
    if (!selectedCustomer) return;
    const customerRef = doc(db, `businessCustomers/${businessName}/customers/${selectedCustomer.customerID}`);

    try {
      await updateDoc(customerRef, selectedCustomer);
      console.log('Customer updated successfully');
      updateCustomers(selectedCustomer); // Update the customers array in the parent component
    } catch (error) {
      console.error('Error updating customer:', error);
    }

    onEditModalClose();
  };

  const handleDeleteClick = (customerId) => {
    setCustomerToDelete(customerId);
    setIsAlertOpen(true);
  };

  const confirmDelete = async () => {
    if (!customerToDelete) return;
    const customerRef = doc(db, `businessCustomers/${businessName}/customers/${customerToDelete}`);

    try {
      await deleteDoc(customerRef);
      console.log('Customer removed successfully');
      updateCustomers(null, customerToDelete); // Remove the customer from the array in the parent component
    } catch (error) {
      console.error('Error removing customer:', error);
    }

    onCloseAlert();
  };

  return (
    <>
      <div className='min-h-screen'>
        {/* Edit Customer Modal */}
        <Modal isOpen={isEditModalOpen} onClose={onEditModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Customer</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>Customer ID</FormLabel>
                <Input isReadOnly value={selectedCustomer?.customerID || ''} />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Full Name</FormLabel>
                <Input placeholder='Full Name' value={selectedCustomer?.fullName || ''} onChange={(e) => setSelectedCustomer({ ...selectedCustomer, fullName: e.target.value })} />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Phone Number</FormLabel>
                <Input placeholder='Phone Number' value={selectedCustomer?.phoneNumber || ''} onChange={(e) => setSelectedCustomer({ ...selectedCustomer, phoneNumber: e.target.value })} />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Address</FormLabel>
                <Input placeholder='Address' value={selectedCustomer?.address || ''} onChange={(e) => setSelectedCustomer({ ...selectedCustomer, address: e.target.value })} />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Email</FormLabel>
                <Input placeholder='Email' value={selectedCustomer?.email || ''} onChange={(e) => setSelectedCustomer({ ...selectedCustomer, email: e.target.value })} />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Date of Birth</FormLabel>
                <Input type="date" placeholder='Date of Birth' value={selectedCustomer?.date_of_birth || ''} onChange={(e) => setSelectedCustomer({ ...selectedCustomer, date_of_birth: e.target.value })} />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Medical / Recreational</FormLabel>
                <Input placeholder='Medical / Recreational' value={selectedCustomer?.medicalStatus || ''} onChange={(e) => setSelectedCustomer({ ...selectedCustomer, medicalStatus: e.target.value })} />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Gender</FormLabel>
                <Input placeholder='Gender' value={selectedCustomer?.gender || ''} onChange={(e) => setSelectedCustomer({ ...selectedCustomer, gender: e.target.value })} />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Age</FormLabel>
                <Input placeholder='Age' type="number" value={selectedCustomer?.age || ''} onChange={(e) => setSelectedCustomer({ ...selectedCustomer, age: e.target.value })} />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={handleSaveEdit}>Save</Button>
              <Button onClick={onEditModalClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Alert Dialog for Deleting Customer */}
        <AlertDialog isOpen={isAlertOpen} leastDestructiveRef={cancelRef} onClose={onCloseAlert} >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>Delete Customer</AlertDialogHeader>
              <AlertDialogBody>Are you sure? You can't undo this action afterwards.</AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={() => setIsAlertOpen(false)}>Cancel</Button>
                <Button colorScheme='red' onClick={confirmDelete} ml={3}>Delete</Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        {/* Customer Table */}
        <div className='max-h-52'>
          <Table>
            <Thead>
              <Tr>
                <Th>Customer ID</Th>
                <Th>Full Name</Th>
                <Th>Phone Number</Th>
                <Th>Address</Th>
                <Th>Email</Th>
                <Th>Date of Birth</Th>
                <Th>Medical Status</Th>
                <Th>Gender</Th>
                <Th>Age</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {customers.map(customer => (
                <Tr key={customer.customerID}>
                  <Td>{customer.customerID}</Td>
                  <Td>{customer.fullName}</Td>
                  <Td>{customer.phoneNumber}</Td>
                  <Td>{customer.address}</Td>
                  <Td>{customer.email}</Td>
                  <Td>{customer.date_of_birth}</Td>
                  <Td>{customer.medicalStatus}</Td>
                  <Td>{customer.gender}</Td>
                  <Td>{customer.age}</Td>
                  <Td>
                    <IconButton aria-label='Edit' icon={<EditIcon />} onClick={() => handleEditClick(customer)} />
                    <IconButton aria-label='Delete' icon={<DeleteIcon />} onClick={() => handleDeleteClick(customer.customerID)} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default CustomersTable;
