import React, { useEffect } from 'react';
import { useAuth } from '../context/auth';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/navbar/NavBar'

const LandingPage = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Home | LeafNet';
  }, []);

  const isBusinessOwner = user?.businessLicense; // Adjust based on your actual user object structure
  const isEmployee = user?.businessId && !isBusinessOwner;

  return (
    <>
      <Navbar />

      <div className='container -mt-96'>
        <p className='text-gray-700 font-bold text-3xl mb-4'>
          Welcome, {user.displayName}!
        </p>
        <p className='bg-gray-900 text-white'>
          {isBusinessOwner ? 'Business Owner View' : isEmployee ? 'Employee View' : 'Welcome'}
        </p>

        {/* Conditional UI for Business Owners */}
        {isBusinessOwner && (
          <div>




          </div>
        )}

        {/* Conditional UI for Employees */}
        {isEmployee && (
          <div>



          </div>
        )}

        <div className='flex' >
          <button type='button'
            className='rounded-xl mt-4 px-4 py-2 bg-[#891c2c] active:bg-opacity-80 hover:bg-opacity-90 drop-shadow-[0_100px_25px_rgba(0,0,0,0.25)] text-gray-200 font-medium' 
            onClick={() => logout()}>
            Logout
          </button>

        </div>
      </div>
    </>
  );
};

export default LandingPage;
