import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../services/firebase'; // Ensure this is the correct path to your Firebase config
import { useAuth } from '../../context/auth';
import { Stat, StatLabel, StatNumber, StatGroup, StatHelpText, Box } from "@chakra-ui/react";

function CustomersData() {
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [lastUpdated, setLastUpdated] = useState('');
  const { user } = useAuth();
  const businessName = user?.businessName;

  const formatDate = (dateString) => {
    const options = {
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      hour12: true
    };
    
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  }
  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        // Fetch customers
        const customersRef = collection(db, `businessCustomers/${businessName}/customers`);
        const customerSnapshot = await getDocs(customersRef);
        setTotalCustomers(customerSnapshot.docs.length);

        // Fetch last updated metadata
        const metadataRef = doc(db, `businessCustomers/${businessName}/metadata/lastUpdated`);
        const metadataSnapshot = await getDoc(metadataRef);
        if (metadataSnapshot.exists()) {
          const metadata = metadataSnapshot.data();
          const lastUpdatedData = metadata.lastUpdated.toDate();
          setLastUpdated(formatDate(lastUpdatedData.toLocaleString()));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (businessName) {
      fetchCustomerData();
    }
  }, [businessName]);

  return (
    <div className='  rounded-xl  w-96'>
        <Box position="relative" h="100vh" p={12} >
            <div className=' border-blue-200 border-4 w-[20rem] px-6 py-4 rounded-xl'>
    <StatGroup >
      <Stat  >
        <StatLabel >Total Customers</StatLabel>
        <StatNumber>{totalCustomers}</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Last Updated</StatLabel>
        <StatNumber>{lastUpdated}</StatNumber>
      </Stat>
    </StatGroup>
    </div>
    </Box>
    </div>
  );
}

export default CustomersData;
