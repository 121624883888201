import React, { useState } from 'react';
import Papa from 'papaparse';
import { db } from '../../services/firebase'; // Make sure this path is correct to your Firebase config
import { collection, addDoc, setDoc, doc} from 'firebase/firestore';
import { useAuth } from '../../context/auth';
import { useToast } from '@chakra-ui/react';

// Define the standard schema and field mapping
const standardSchema = {

    customerID: null,
    fullName: null,
    phoneNumber: null,
    address: null,
    email: null,
    date_of_birth: null,
    medicalStatus: null,
    gender: null,
    age: null,

  };
  
  const fieldMapping = {
    'customer id': 'customerID',
    'Customer ID': 'customerID',
    'customer_id': 'customerID',
    'First Name' : 'firstName',
    'first name' : 'firstName',
    'full_name' : 'fullName',
    'phone_number' : 'phoneNumber',
    'date_of_birth' : 'date_of_birth',
    'medical_status' : 'medicalStatus',
    'Last Name' : 'lastName',
    'last Name' : 'lastName',
    'Full Name': 'fullName',
    'Name': 'fullName',
    'full name': 'fullName',
    'Phone Number': 'phoneNumber',
    'phone number': 'phoneNumber',
    'Phone': 'phoneNumber',
    'Address': 'address',
    'address': 'address',
    'Email': 'email',
    'email': 'email',
    'Date of Birth': 'date_of_birth',
    'DOB': 'date_of_birth',
    'date of birth': 'date_of_birth',
    'Medical Status': 'medicalStatus',
    'medical status': 'medicalStatus',
    'Medical/Recreational': 'medicalStatus',
    'Gender': 'gender',
    'gender': 'gender',
    'Sex': 'gender',
    'Age': 'age',
    'age': 'age'
  };
  

const CustomerUpload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth(); // Get user information from context
  const toast = useToast(); // Chakra UI Toast

  const handleFileUpload = event => {
    setIsLoading(true);
    const file = event.target.files[0];
    const businessName = user?.businessName;

    if (!businessName) {
      toast({
        title: 'Error',
        description: 'Business name is not set.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }

    const customersCollectionRef = collection(db, `businessCustomers/${businessName}/customers`);

    Papa.parse(file, {
      complete: async (result) => {
        try {
          const transformedData = result.data.map(row => {
            const transformedRow = { ...standardSchema };

            Object.keys(row).forEach(key => {
              const standardKey = fieldMapping[key];
              if (standardKey) {
                transformedRow[standardKey] = row[key];
              }
            });
            if (transformedRow.firstName && transformedRow.lastName) {
                transformedRow.fullName = `${transformedRow.firstName} ${transformedRow.lastName}`
            }
            return transformedRow;
          });


          for (const item of transformedData) {
            await addDoc(customersCollectionRef, item);
          }

          const metadataRef = doc(db, `businessCustomers/${businessName}/metadata`, 'lastUpdated');
          await setDoc(metadataRef, { lastUpdated: new Date() });

          toast({
            title: 'Success',
            description: 'Data uploaded successfully to Firebase!',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        } catch (error) {
          console.error('Error uploading to Firebase:', error);
          toast({
            title: 'Error',
            description: 'Error uploading data to Firebase.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setIsLoading(false);
        }
      },
      header: true,
      skipEmptyLines: true
    });
  };

  return (
    <div>
      <input type="file" onChange={handleFileUpload} accept=".csv" disabled={isLoading} />
      {isLoading && <p>Uploading data...</p>}
    </div>
  );
};

export default CustomerUpload;
