import React, { useState } from 'react';
import Papa from 'papaparse';
import { db } from '../../services/firebase'; // Make sure this path is correct to your Firebase config
import { collection, addDoc } from 'firebase/firestore';
import { useAuth } from '../../context/auth';
import { useToast } from '@chakra-ui/react';

// Define the standard schema and field mapping
const standardSchema = {
  orderid: null,
  status: null,
  summaryInfo: null,
  totalAmount: null,
  serviceUsed: null,
  receipt: null,
  customer: null,
  phoneNumber: null,
  email: null,    
  address: null,
  orderDate: null,
  deliveryDate: null,
  completedDate: null,
  paymentMethod: null,
  itemsInfo: null,
  notes: null,
  specialInstructions: null,
  firstName: null,
  lastName: null,
  fullName: null,
  promoCode: null,
  discountUsed: null
};

const fieldMapping = {
  'Order ID': 'orderid',
  'order id': 'orderid',
  'Order Status': 'status',
  'status': 'status',
  'Summary': 'summaryInfo',
  'Order Summary': 'summaryInfo',
  'Total': 'totalAmount',
  'Amount': 'totalAmount',
  'Service': 'serviceUsed',
  'Delivery Service': 'serviceUsed',
  'Receipt Number': 'receipt',
  'Customer Name': 'customer',
  'Customer': 'customer',
  'Phone': 'phoneNumber',
  'Mobile': 'phoneNumber',
  'Email Address': 'email',
  'Order Address': 'address',
  'Shipping Address': 'address',
  'Order Date': 'orderDate',
  'Date Ordered': 'orderDate',
  'Delivery Date': 'deliveryDate',
  'Date Delivered': 'deliveryDate',
  'Completion Date': 'completedDate',
  'Date Completed': 'completedDate',
  'Payment Method': 'paymentMethod',
  'Items Detail': 'itemsInfo',
  'Product Info': 'itemsInfo',
  'Order Notes': 'notes',
  'Instructions': 'specialInstructions',
  'First Name': 'firstName',
  'Last Name': 'lastName',
  'Full Name': 'fullName',
  'Name': 'fullName',
  'Discount Code': 'promoCode',
  'Promotion Code': 'promoCode',
  'Discount': 'discountUsed',
  'Discount Applied': 'discountUsed'
};

const OrdersUpload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth(); // Get user information from context
  const toast = useToast(); // Chakra UI Toast

  const handleFileUpload = event => {
    setIsLoading(true);
    const file = event.target.files[0];
    const businessName = user?.businessName;

    if (!businessName) {
      toast({
        title: 'Error',
        description: 'Business name is not set.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }

    const orderCollectionRef = collection(db, `orders/${businessName}/orders`);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (result) => {
        try {
          const transformedData = result.data.map(row => {
            const transformedRow = { ...standardSchema };

            Object.keys(row).forEach(key => {
              const trimmedKey = key.trim();
              const standardKey = fieldMapping[trimmedKey];
              if (standardKey) {
                transformedRow[standardKey] = row[key].trim();
              }
            });

            return transformedRow;
          });

          for (const item of transformedData) {
            await addDoc(orderCollectionRef, item);
          }

          toast({
            title: 'Success',
            description: 'Data uploaded successfully to Firebase!',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        } catch (error) {
          console.error('Error uploading to Firebase:', error);
          toast({
            title: 'Error',
            description: 'Error uploading data to Firebase.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setIsLoading(false);
        }
      },
    });
  };

  return (
    <div>
      <input type="file" onChange={handleFileUpload} accept=".csv" disabled={isLoading} />
      {isLoading && <p>Uploading data...</p>}
    </div>
  );
};

export default OrdersUpload;
