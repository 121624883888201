import React, { useState, useRef } from 'react';
import {
  Button, Table, Thead, Tbody, Tr, Th, Td, IconButton, useDisclosure,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
  ModalBody, ModalFooter, FormControl, FormLabel, Input, AlertDialog,
  AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useAuth } from '../../context/auth';
import { doc, updateDoc, deleteDoc, setDoc, collection } from 'firebase/firestore';
import { db } from '../../services/firebase';

const InventoryTable = ({ inventory = [], updateInventoryItem }) => {
  const { isOpen: isAddModalOpen, onOpen: onAddModalOpen, onClose: onAddModalClose } = useDisclosure();
  const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalClose } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState(null);
  const [newItem, setNewItem] = useState({});
  const { user } = useAuth();

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const cancelRef = useRef();

  const onCloseAlert = () => setIsAlertOpen(false);

  const handleDeleteClick = (itemId) => {
    setItemToDelete(itemId);
    setIsAlertOpen(true);
  };

  const confirmDelete = async () => {
    if (!itemToDelete) return;

    const businessName = user?.businessName;
    const itemRef = doc(db, `inventory/${businessName}/items`, itemToDelete);

    try {
      await deleteDoc(itemRef);
      updateInventoryItem(null, itemToDelete); // Pass null and the ID to signal deletion
      console.log('Item removed successfully');
    } catch (error) {
      console.error('Error removing item:', error);
    }

    onCloseAlert();
  };

  const handleEditClick = (item) => {
    setSelectedItem(item);
    onEditModalOpen();
  };

  const handleAddClick = () => {
    setNewItem({});
    onAddModalOpen();
  };

  const handleInputChange = (e, setItem) => {
    const { name, value } = e.target;
    setItem(prevItem => ({ ...prevItem, [name]: value }));
  };

  const handleSaveEdit = async () => {
    if (!selectedItem) return;

    const businessName = user?.businessName;
    const itemRef = doc(db, `inventory/${businessName}/items`, selectedItem.id);

    try {
      await updateDoc(itemRef, selectedItem);
      updateInventoryItem(selectedItem); // Pass the updated item
      console.log('Item updated successfully');
    } catch (error) {
      console.error('Error updating item:', error);
    }

    onEditModalClose();
  };

  const handleSaveAdd = async () => {
    if (!newItem) return;

    const businessName = user?.businessName;
    const newId = doc(collection(db, `inventory/${businessName}/items`)).id;
    const itemRef = doc(db, `inventory/${businessName}/items`, newId);

    try {
      await setDoc(itemRef, { ...newItem, id: newId });
      updateInventoryItem({ ...newItem, id: newId }); // Pass the new item
      console.log('Item added successfully');
    } catch (error) {
      console.error('Error adding item:', error);
    }

    onAddModalClose();
  };

  return (
    <>
      <div className='min-h-screen'>
        <Button leftIcon={<AddIcon />} colorScheme="teal" onClick={handleAddClick}>
          Add Item
        </Button>

        <Modal isOpen={isEditModalOpen} onClose={onEditModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Item</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>Brand</FormLabel>
                <Input 
                  name="brand"
                  placeholder='Brand' 
                  value={selectedItem?.brand || ''} 
                  onChange={(e) => handleInputChange(e, setSelectedItem)} 
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Category</FormLabel>
                <Input 
                  name="category"
                  placeholder='Category' 
                  value={selectedItem?.category || ''} 
                  onChange={(e) => handleInputChange(e, setSelectedItem)} 
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Weight</FormLabel>
                <Input 
                  name="weight"
                  placeholder='Weight' 
                  value={selectedItem?.weight || ''} 
                  onChange={(e) => handleInputChange(e, setSelectedItem)} 
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Potency/Percentage</FormLabel>
                <Input 
                  name="potency"
                  placeholder='Potency/Percentage' 
                  value={selectedItem?.potency || ''} 
                  onChange={(e) => handleInputChange(e, setSelectedItem)} 
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Strain</FormLabel>
                <Input 
                  name="strain"
                  placeholder='Strain' 
                  value={selectedItem?.strain || ''} 
                  onChange={(e) => handleInputChange(e, setSelectedItem)} 
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Type of Strain</FormLabel>
                <Input 
                  name="type"
                  placeholder='Type of Strain' 
                  value={selectedItem?.type || ''} 
                  onChange={(e) => handleInputChange(e, setSelectedItem)} 
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Price ($)</FormLabel>
                <Input 
                  name="price"
                  placeholder='Price ($)' 
                  value={selectedItem?.price || ''} 
                  onChange={(e) => handleInputChange(e, setSelectedItem)} 
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Stock Quantity</FormLabel>
                <Input 
                  name="stockQuantity"
                  placeholder='Stock Quantity' 
                  value={selectedItem?.stockQuantity || ''} 
                  onChange={(e) => handleInputChange(e, setSelectedItem)} 
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Supplier</FormLabel>
                <Input 
                  name="supplier"
                  placeholder='Supplier' 
                  value={selectedItem?.supplier || ''} 
                  onChange={(e) => handleInputChange(e, setSelectedItem)} 
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={handleSaveEdit}>
                Save
              </Button>
              <Button onClick={onEditModalClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={isAddModalOpen} onClose={onAddModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Item</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>Brand</FormLabel>
                <Input 
                  name="brand"
                  placeholder='Brand' 
                  value={newItem?.brand || ''} 
                  onChange={(e) => handleInputChange(e, setNewItem)} 
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Category</FormLabel>
                <Input 
                  name="category"
                  placeholder='Category' 
                  value={newItem?.category || ''} 
                  onChange={(e) => handleInputChange(e, setNewItem)} 
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Weight</FormLabel>
                <Input 
                  name="weight"
                  placeholder='Weight' 
                  value={newItem?.weight || ''} 
                  onChange={(e) => handleInputChange(e, setNewItem)} 
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Potency/Percentage</FormLabel>
                <Input 
                  name="potency"
                  placeholder='Potency/Percentage' 
                  value={newItem?.potency || ''} 
                  onChange={(e) => handleInputChange(e, setNewItem)} 
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Strain</FormLabel>
                <Input 
                  name="strain"
                  placeholder='Strain' 
                  value={newItem?.strain || ''} 
                  onChange={(e) => handleInputChange(e, setNewItem)} 
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Type of Strain</FormLabel>
                <Input 
                  name="type"
                  placeholder='Type of Strain' 
                  value={newItem?.type || ''} 
                  onChange={(e) => handleInputChange(e, setNewItem)} 
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Price ($)</FormLabel>
                <Input 
                  name="price"
                  placeholder='Price ($)' 
                  value={newItem?.price || ''} 
                  onChange={(e) => handleInputChange(e, setNewItem)} 
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Stock Quantity</FormLabel>
                <Input 
                  name="stockQuantity"
                  placeholder='Stock Quantity' 
                  value={newItem?.stockQuantity || ''} 
                  onChange={(e) => handleInputChange(e, setNewItem)} 
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Supplier</FormLabel>
                <Input 
                  name="supplier"
                  placeholder='Supplier' 
                  value={newItem?.supplier || ''} 
                  onChange={(e) => handleInputChange(e, setNewItem)} 
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={handleSaveAdd}>
                Save
              </Button>
              <Button onClick={onAddModalClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <div className='max-h-52'>
          <Table>
            <Thead>
              <Tr>
                <Th>Brand</Th>
                <Th>Category</Th>
                <Th>Weight</Th>
                <Th>Potency/Percentage</Th>
                <Th>Strain</Th>
                <Th>Type of Strain</Th>
                <Th>Price ($)</Th>
                <Th>Stock Quantity</Th>
                <Th>Supplier</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {inventory.map(item => (
                <Tr key={item.id}>
                  <Td>{item.brand}</Td>
                  <Td>{item.category}</Td>
                  <Td>{item.weight}</Td>
                  <Td>{item.potency}</Td>
                  <Td>{item.strain}</Td>
                  <Td>{item.type}</Td>
                  <Td>{item.price}</Td>
                  <Td>{item.stockQuantity}</Td>
                  <Td>{item.supplier}</Td>
                  <Td>
                    <IconButton aria-label="Edit" icon={<EditIcon />} onClick={() => handleEditClick(item)} />
                    <IconButton aria-label="Delete" icon={<DeleteIcon />} onClick={() => handleDeleteClick(item.id)} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <AlertDialog
            isOpen={isAlertOpen}
            leastDestructiveRef={cancelRef}
            onClose={onCloseAlert}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Delete Item
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure? You can't undo this action afterwards.
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onCloseAlert}>
                    Cancel
                  </Button>
                  <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </div>
      </div>
    </>
  );
};

export default InventoryTable;
