import React, {useState, useEffect} from 'react'
import Navbar from '../../components/navbar/NavBar'
import CustomerUpload from './CustomerUpload'
import CustomersTable from './CustomersTable';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../services/firebase'; // Make sure this path is correct to your Firebase config
import { useAuth } from '../../context/auth';
import CustomersData from './CustomersData';


function Customers() {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const { user } = useAuth();
    const businessName = user?.businessName;

  useEffect(() => {
      const fetchCustomers = async () => {
        setLoading(true);
        try {
          const customersCollectionRef = collection(db, `businessCustomers/${businessName}/customers/`);
          const querySnapshot = await getDocs(customersCollectionRef);
          const customerData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setCustomers(customerData);
        } catch (error) {
          console.error('Error fetching customer data:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchCustomers();
    }, []);
    
  return (
    <>
    <Navbar />
    <div className="-mt-96  absolute">
        <h1>Customers Tabs</h1>
        
        <Tabs variant='soft-rounded' colorScheme='green'>
        <TabList>
            <Tab>Dashboard</Tab>
            <Tab>Customers</Tab>
        </TabList>
        <TabPanels>
            <TabPanel>
            <div className=' max-h-24'>
            <p>one!</p>
            <CustomersData />
            </div>
            </TabPanel>
            <TabPanel>
            <div className='max-h-24'>
            <p></p>
            <CustomerUpload />
            <div className=''>
            <CustomersTable customers={customers} updateCustomerData={setCustomers} />
            </div>
            </div>
            </TabPanel>
        </TabPanels>
        </Tabs>




        </div>
    </>

  )
}

export default Customers