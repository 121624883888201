import React, { useState } from 'react';
import Papa from 'papaparse';
import { db } from '../../services/firebase'; // Make sure this path is correct to your Firebase config
import { collection, addDoc } from 'firebase/firestore';
import { useAuth } from '../../context/auth';
import { useToast } from '@chakra-ui/react';

// Define the standard schema and field mapping
const standardSchema = {
    brand: null,
    category: null,
    itemName: null,
    weight: null,
    potency: null,
    strain: null,
    type: null,
    price: null,
    productId: null,
    stockQuantity: null,
    supplier: null,
    percentage: null
  };
  
  const fieldMapping = {
    'Category': 'category',
    'Brand': 'brand',
    'Item Name': 'itemName',
    'Weight': 'weight',
    'Potency/Percentage': 'potency',
    'Strain': 'strain',
    'Type of Weed': 'type',
    'Price ($)': 'price',
    'Cost': 'price',
    'Product ID': 'productId',
    'ID': 'productId',
    'Stock Quantity': 'stockQuantity',
    'Quantity': 'stockQuantity',
    'THC %': 'percentage',
    'CBD %': 'percentage',
    '%' : 'percentage',
    'Supplier': 'supplier',
    'Vendor': 'supplier',
  };

const FileUpload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth(); // Get user information from context
  const toast = useToast(); // Chakra UI Toast

  const handleFileUpload = event => {
    setIsLoading(true);
    const file = event.target.files[0];
    const businessName = user?.businessName;

    if (!businessName) {
      toast({
        title: 'Error',
        description: 'Business name is not set.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }

    const inventoryCollectionRef = collection(db, `inventory/${businessName}/items`);

    Papa.parse(file, {
      complete: async (result) => {
        try {
          const transformedData = result.data.map(row => {
            const transformedRow = { ...standardSchema };

            Object.keys(row).forEach(key => {
              const standardKey = fieldMapping[key];
              if (standardKey) {
                transformedRow[standardKey] = row[key];
              }
            });

            return transformedRow;
          });

          for (const item of transformedData) {
            await addDoc(inventoryCollectionRef, item);
          }

          toast({
            title: 'Success',
            description: 'Data uploaded successfully to Firebase!',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        } catch (error) {
          console.error('Error uploading to Firebase:', error);
          toast({
            title: 'Error',
            description: 'Error uploading data to Firebase.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setIsLoading(false);
        }
      },
      header: true,
      skipEmptyLines: true
    });
  };

  return (
    <div>
      <input type="file" onChange={handleFileUpload} accept=".csv" disabled={isLoading} />
      {isLoading && <p>Uploading data...</p>}
    </div>
  );
};

export default FileUpload;
