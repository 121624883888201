import React from 'react'
import Navbar from '../../components/navbar/NavBar'
import OrdersUpload from './OrdersUpload'
import InventoryTable from '../inventory/InventoryTable'
import OrdersTable from './OrdersTable'
function Orders() {
  return (
    <>
    <Navbar />
    
    <div>
      <OrdersUpload />
      <OrdersTable />
    </div>
    </>
  )
}

export default Orders