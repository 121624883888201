import React, { useEffect, useState,  } from 'react';
import { useAuth } from '../../context/auth';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar/NavBar';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../../services/firebase'; // Make sure this path is correct to your Firebase config
import Hub from './Hub';
import Messenger from './Messenger';

function Marketing() {
  return (
    <>
    <Navbar />
    <div className=''>
        <div className='absolute left-32'>
        <Hub />
        </div>
        <div className=''>
        <Messenger />
        </div>
    </div>
    </>
  )
}

export default Marketing