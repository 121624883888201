import { lazy, Suspense } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Outlet,
  Navigate,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaSpinner } from 'react-icons/fa';
import * as PATHS from './constants/routes';
import { AuthProvider, useAuth } from './context/auth';
import LandingPage from './pages/LandingPage';
import LoadingPage from './pages/LoadingPage';
import Inventory from './pages/inventory/Inventory'
import Customers from './pages/customers/Customers';
import Orders from './pages/orders/Orders';
import { ChakraProvider } from '@chakra-ui/react'
;
import Marketing from './pages/marketing/Marketing';


const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/Register'));
const Profile = lazy(() => import('./pages/Profile'));

const PrivateRoute = () => {
  const { isLoggedIn } = useAuth();
  return isLoggedIn ? <Outlet /> : <Navigate to={PATHS.LOGIN} />;
};

const ProfileRoute = () => {
  const { user } = useAuth();
  if (!user.address) {
    toast.info('Please finish your profile.');
    return <Navigate to={PATHS.PROFILE} />;
  }
  return <Outlet />;
};

const PublicRoute = () => {
  const { isLoggedIn } = useAuth();
  return !isLoggedIn ? <Outlet /> : <Navigate to={PATHS.DASHBOARD} />;
};

const App = () => {
  return (
    <ChakraProvider>
    <AuthProvider>

      <div
        className='flex justify-center bg-cover bg-no-repeat items-center h-screen'
        style={{
        }}
      >

        <Suspense fallback={<LoadingPage />}>
          
          <BrowserRouter>
            <Routes>

              <Route element={<PublicRoute />}>
                <Route path={PATHS.LOGIN} element={<Login />} />
                <Route path={PATHS.SIGNUP} element={<Register />} />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route element={<ProfileRoute />}>
                  <Route path={PATHS.DASHBOARD} element={<LandingPage />} />
                  <Route path={PATHS.MARKETING} element={<Marketing />} />
                  <Route path={PATHS.INVENTORY} element={<Inventory />} />
                  <Route path={PATHS.CUSTOMERS} element={<Customers />} />
                  <Route path={PATHS.ORDERS} element={<Orders />} />
                </Route>
                <Route path={PATHS.PROFILE} element={<Profile />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </Suspense>
      </div>
    </AuthProvider>
    </ChakraProvider>
  );
};

export default App;
