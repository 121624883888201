import React, { useEffect, useState,  } from 'react';
import { useAuth } from '../../context/auth';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar/NavBar';
import { collection, getDocs, query } from 'firebase/firestore';
import { db } from '../../services/firebase'; // Make sure this path is correct to your Firebase config


function Hub() {
  const { user, logout } = useAuth();
  const [currentDateTime, setCurrentDateTime] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const formattedDateTime = now.toLocaleString('en-US', { weekday: 'long', hour: '2-digit', minute: '2-digit', hour12: true });
      setCurrentDateTime(formattedDateTime);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
    <div>
    

        <div className="w-[407px] h-[66px] bg-white rounded-[10px] border-2 border-teal-300" >
          <h1 className="text-slate-700 text-[28px] font-black mt-2 ml-4 italic">{user.businessName}</h1>
          <h2 className="text-slate-700 text-xl font-light -mt-[2.10rem] ml-[11.5rem]" >{currentDateTime}</h2>

        </div>

        <div>

        <div className="w-[68px] mt-[1rem]  h-[71px] bg-white rounded-[10px] border-2 border-teal-300" > 
            <h1 className="text-slate-700 text-[28px] font-black mt-2  text-center select-none"> 2 </h1>
        </div>
        <div className="ml-24 relative -top-[4.5rem] w-[311px] h-[71px] bg-white rounded-[10px] border-2 border-teal-300" >
        <h1  className="text-slate-700 text-[28px] font-black mt-2  text-center select-none"> Open Campaigns</h1>
        </div>   

        </div>
        
        <div className="-mt-[3.5rem]  w-[407px] h-[66px] bg-white rounded-[10px] border-2 border-teal-300" >
          <h1 className="text-slate-700 text-[28px] font-black mt-2  text-center select-none">Access Content Suite</h1>
        </div>

        <div className=" relative top-[1rem] w-[407px] h-[66px] bg-white rounded-[10px] border-2 border-teal-300" >
          <h1 className="text-slate-700 text-[28px] font-black mt-2 text-center select-none">Feedback Survey’s</h1>
        </div>

        <div className="relative top-[2rem]  w-[407px] h-[66px] bg-white rounded-[10px] border-2 border-teal-300" >
          <h1 className="text-slate-700 text-[28px] font-black mt-2 text-center select-none">Start a Campaign</h1>
        </div>

    </div>
    </>
  )
} 

export default Hub